import { useSessionStorage }          from "@mantine/hooks";
import dynamic                        from "next/dynamic";
import { useRouter }                  from "next/router";
import React, { useEffect, useState } from "react";

import {
	useGetLoggedIn,
	useGetProfileData
} from "_common/hooks/auth";
import {
	useGetOpenUserOnboardingModal,
	useGetUserOnBoardingAborted,
	useSetOpenUserOnboardingModal
} from "_common/hooks/global";
import { isPageRoute } from "_common/utils";

const UserOnboarding = dynamic ( () => import ( "_components/molecules/userOnboarding" ) );

const Onboarding = () => {
	const [ step, setStep ]         = useState ( 1 );
	const router                    = useRouter ();
	const isLoggedInUser            = useGetLoggedIn ();
	const onboardingAborted         = useGetUserOnBoardingAborted ();
	const userProfile               = useGetProfileData ();
	const onboard                   = useGetOpenUserOnboardingModal ();
	const toggleUserOnboardingPopup = useSetOpenUserOnboardingModal ();
	const [ walletModalState ]      = useSessionStorage ( { key: "feat-wallet-modal" } );

	let timer;

	const checkPageRoute = page => {
		return isPageRoute ( { router, page } );
	};

	useEffect ( () => {
		if ( userProfile && userProfile.onboardingStatus?.processComplete === false && checkPageRoute ( "home" ) && isLoggedInUser ) {
			if ( userProfile?.onboardingStatus?.updatedFieldsStatus?.nameUpdated === false ) {
				setStep ( 1 );
			} else if ( userProfile?.onboardingStatus?.updatedFieldsStatus?.localeUpdated === false ) {
				setStep ( 2 );
			} else if ( userProfile?.onboardingStatus?.updatedFieldsStatus?.followGamesUpdated === false ) {
				setStep ( 3 );
			}
			timer = setTimeout ( () => {
				toggleUserOnboardingPopup ( true );
			}, 10000 );
		} else {
			toggleUserOnboardingPopup ( false );
		}

		return () => {
			clearTimeout ( timer );
		};

	}, [ userProfile?.onboardingStatus ] );

	if ( !onboard || onboardingAborted || !checkPageRoute ( "home" ) || walletModalState?.opened )
		return null;

	return <UserOnboarding onboardingStep = { step } />;
};

export default Onboarding;
